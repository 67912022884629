import React from "react"
import Layout from "../components/layout";
import Title from "../components/title";
import Promo from "../components/promo";

const Why = ({ location }) => (

    <Layout
      path={location.pathname}>

      <Title
        subtitle="Why use Slate?"
        title="Keeping your schedule & projects on track."
        width="max-w-xs md:max-w-md lg:max-w-xl" />

      <div className="container max-w-3xl mx-auto">

        <div className="richtext mx-auto container space-y-8">

          <p>You're either organised or not. It's that simple.</p>

          <p>It's easy to see why people aren't organised. It takes lots of little jobs to stay on top of things. 
            Jobs you'd rather not do so you put them off. Next thing you know, a month has gone by and you haven't invoiced 
            your last client.</p>

          <p>Any of these sound familiar?</p>

          <ul>
            <li>Not sure what you worked on two weeks ago</li>
            <li>Not sure if you invoiced those days yet</li>
            <li>Not sure what you're working on in the next few weeks</li>
            <li>Not sure when you can start that new project</li>
            <li>Not sure if the project you finished was profitable</li>
            <li>Not sure if you're going to earn enough money this month</li>
            <li>Not sure if you're overbooking yourself</li>
            <li>Not sure if you're too reliant on this client for revenue</li>
          </ul>

          <p>These are all common concerns freelancers have, you're not alone. Before we built Slate we'd run into these issues more than we'd like to admit
            – we're freelancers too.</p>

          <p>We know you'd rather focus on your work because that's why you become a freelancer in the first place. It wasn't to manage a calendar, 
            or add up finances in a spreadsheet.</p>

          <p>You've already tried a combination of calendars, spreadsheets and notes to get organised. We did too for a bit, but we never felt
            in control. We'd be jumping from one app to another, often forgetting things along the way.</p>

          <p>Slate is the easiest way for freelancer designers & developers to get organised.</p>

          <p>We hope it helps you as much as it's helped us.</p>

          <p className="text-dark">Chris & Simon</p>

          <hr className="hidden" />

          <blockquote className="hidden">I was having trouble tracking and scheduling my days. If I haven’t invoiced in a couple of weeks I had 
            trouble recalling exactly which days I did what. I kept jumping between the calendar or Notion. Slate made things crystal clear.</blockquote>

        </div>

      </div>

      <Promo theme="light" />

    </Layout>
)

export default Why;